import styled from "styled-components";
import { BACKGROUND_COLOR } from "../../constants";

export const LoginContainer = styled.div`
  min-height: 100vh;
  background-color: ${BACKGROUND_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 42px;
  margin-right: 42px;
`;

export const userTextField = { mt: "24px", width: "250px" };
export const passwordTextField = { mt: "32px", width: "250px" };
export const errorMessage = {
  mt: "12px",
  lineHeight: "1.5",
  color: "red",
  fontSize: "12px",
};
export const submitButton = (error) => ({
  // Cuando hay error, mt = (48 - errorMessage.mt - errorMessage.fontSize*errorMessage.lineHeight)px
  mt: error ? "18px" : "48px",
  ml: "auto",
  mb: "48px",
});
