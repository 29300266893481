import React, { useState } from "react";
// import DocumentTitle from "react-document-title";
import { Card, CircularProgress } from "@mui/material";
import { Navigate } from "react-router-dom";

import Background from "../components/Login/Background";
import { LoginContainer } from "../components/Login/styles";
import Logo from "../components/Login/Logo";
import Form from "../components/Login/Form";
// import api from "../api/configurationManagerApi";
import { useStoreCM } from "../store/Store";

import { HOME, API_RESPONSE_UNKNOW_ERROR, API_RESPONSE_WRONG_CREDENTIALS } from "../constants";

const Login = ({ enqueueSnackbar }) => {
  console.log("Login - INICIO");
  const [error, setError] = useState(false);

  const login = useStoreCM(state => state.login);
  const isLoggedIn = useStoreCM(state => state.isLoggedIn);
  const showLoadingPage = useStoreCM(state => state.showLoadingPage);

  if (isLoggedIn) {
    console.log("Login - mientras renderizo el componente veo que el usuario esta logeado, entonces navego a ", `/${HOME}`);
    return <Navigate to={`/${HOME}`} replace />;
  }

  const onLogin = async (username, password) => {
    console.log("Login.onLogin - INICIO");
    const respuesta = await login(username, password);
    console.log("Login.onLogin - respuesta del login: ", respuesta);
    if (respuesta === API_RESPONSE_WRONG_CREDENTIALS) {
      console.log("Login.onLogin - seteo error por credenciales incorrectas");
      setError(true);
    }
    if (respuesta === API_RESPONSE_UNKNOW_ERROR) {
      console.log("Login.onLogin - seteo error por error desconocido");
      enqueueSnackbar("Ocurrió un error inesperado al iniciar sesión", {
        variant: "error",
      });
    }
    console.log("Login.onLogin - FIN");
  }

  return (
    // <DocumentTitle title="Configuration Manager - Iniciar sesión">
    <LoginContainer>
      <Background />
      {showLoadingPage && <CircularProgress />}
      {!showLoadingPage && (
        <LoginCard>
          <Logo />
          <Form
            onSubmit={onLogin}
            error={error}
            resetError={() => setError(false)}
          />
        </LoginCard>
      )}
    </LoginContainer>
    // </DocumentTitle>
  );
};

const LoginCard = (props) => (
  <Card
    sx={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      boxShadow: `0 0 2px 3px rgba(0, 0, 0, 0.1)`,
      borderRadius: "8px",
      background: "#fff",
      zIndex: 1,
    }}
  >
    {props.children}
  </Card>
);

export default Login;
