import React from "react";

const Logo = () => (
  <>
    <img
      src="/logo_letter.svg"
      alt="SRT"
      style={{ width: "40%", marginTop: 16 }}
    />
  </>
);

export default Logo;
