import React, { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { SnackbarProvider, useSnackbar } from "notistack";

import Login from "./pages/Login";
import NoPage from "./pages/NoPage";
import MainView from "./pages/MainView/MainView";
import { useStoreCM } from "./store/Store";
// import PrintProps from "./pages/PrintProps";
import ProtectedRoute from "./components/ProtectedRoute";

import "./App.css";

import {
  PRIMARY_COLOR,
  LIGHT_COLOR,
  BLUE_COLOR,
  GREEN_COLOR,
  ORANGE_COLOR,
  OK_COLOR,
  WARNING_COLOR,
  ERROR_COLOR,
  LIGHT_WARNING_COLOR,
  LIGHT_ERROR_COLOR,
  LIGHT_OK_COLOR,
  LIGHT_BLUE_COLOR,
  DARK_OK_COLOR,
  DARK_WARNING_COLOR,
  DARK_ERROR_COLOR,
  HOME,
  TOKEN_NAME,
  LOGIN,
  WHITE_COLOR,
  BACKGROUND_COLOR,
  USER_NAME,
} from "./constants";

const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      light: LIGHT_COLOR,
    },
    secondary: {
      main: LIGHT_COLOR,
    },
    background: {
      main: BACKGROUND_COLOR,
    },
    text: {
      primary: PRIMARY_COLOR,
      disabled: LIGHT_COLOR,
    },
    status: {
      ok: {
        main: OK_COLOR,
        light: LIGHT_OK_COLOR,
        dark: DARK_OK_COLOR,
      },
      warning: {
        main: WARNING_COLOR,
        light: LIGHT_WARNING_COLOR,
        dark: DARK_WARNING_COLOR,
      },
      error: {
        main: ERROR_COLOR,
        light: LIGHT_ERROR_COLOR,
        dark: DARK_ERROR_COLOR,
      },
    },
    blue: {
      main: BLUE_COLOR,
      light: LIGHT_BLUE_COLOR,
    },
    green: {
      main: GREEN_COLOR,
    },
    orange: {
      main: ORANGE_COLOR,
    },
    gray: {
      main: WHITE_COLOR,
    },
  },
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          "&.MuiSelect-root .Mui-disabled": {
            color: "rgba(0,0,0,1)",
            textFillColor: "rgba(0,0,0,1)",
          },
          "&.MuiSelect-root.Mui-disabled .MuiSvgIcon-root ": {
            display: "none",
          },
        },
      },
    },
  },
});

const WrapperApp = () => (
  <SnackbarProvider
    maxSnack={1}
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
  >
    <App />
  </SnackbarProvider>
);

function App() {

  const { enqueueSnackbar } = useSnackbar(); // TODO: Cuando sea necesario agregar closeSnackbar

  const token = useStoreCM(state => state.token);
  const username = useStoreCM(state => state.username);
  const isLoggedIn = useStoreCM(state => state.isLoggedIn);
  const showLoadingPage = useStoreCM(state => state.showLoadingPage);

  console.log("App - token ", token);
  console.log("App - username: ", username);
  console.log("App - isLoggedIn: ", isLoggedIn);
  console.log("App - showLoadingPage: ", showLoadingPage);

  const validateToken = useStoreCM(state => state.validateToken);
  const tokenIsMissing = useStoreCM(state => state.tokenIsMissing);

  useEffect(() => {
    // Cuando inicia la APP en caso de tener un token guardado verifico que siga siendo valido.
    var token = localStorage.getItem(TOKEN_NAME);
    var username = localStorage.getItem(USER_NAME);
    console.log("App.useEffect - token y username: ", token, username);

    if (token && username) {
      console.log("App.useEffect - llamo a validate token");
      validateToken(token, username);
    } else {
      tokenIsMissing();
    }
    console.log("App.useEffect - FIN");
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route
          path="/"
          element={
            isLoggedIn ? (
              <Navigate to={`/${HOME}`} />
            ) : (
              <Navigate to={`/${LOGIN}`} />
            )
          }
        />
        <Route
          path={`/${LOGIN}`}
          element={<Login enqueueSnackbar={enqueueSnackbar} />}
        />
        <Route
          path={`/${HOME}`}
          element={
            <ProtectedRoute>
              <MainView enqueueSnackbar={enqueueSnackbar} />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </ThemeProvider>
  );
}

export default WrapperApp;
