import React from "react";

import { useStoreCM } from "../../store/Store";
import { Container } from "@mui/material";

import TopBar from "../../components/Topbar/Topbar";

const MainView = () => {
  console.log("MainView - INICIO");

  const token = useStoreCM((state) => state.token);
  const username = useStoreCM((state) => state.username);
  const isLoggedIn = useStoreCM((state) => state.isLoggedIn);
  const showLoadingPage = useStoreCM((state) => state.showLoadingPage);

  const logout = useStoreCM((state) => state.logout);

  return (
    <>
      <TopBar onLogout={logout} username={username} />
      <Container>
        <h2>Home</h2>
        <p>
          <b>token</b>: {token}
        </p>
        <p>
          <b>username</b>: {username}
        </p>
        <p>
          <b>isLoggedIn</b>: {`${isLoggedIn}`}
        </p>
        <p>
          <b>showLoadingPage</b>: {`${showLoadingPage}`}
        </p>
        <p>
          <b>process.env.NODE_ENV</b>: {process.env.NODE_ENV}
        </p>
        <p>
          <b>process.env.REACT_APP_CM_URL</b>: {process.env.REACT_APP_CM_URL}
        </p>
        <button onClick={logout}>Logout</button>
      </Container>
    </>
  );
};

export default MainView;
