import React from "react";
import { Navigate } from "react-router-dom";

import { useStoreCM } from "../store/Store";

const ProtectedRoute = ({ children }) => {
  const isLoggedIn = useStoreCM(state => state.isLoggedIn);

  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
