export const BACKGROUND_COLOR = "#f2f2f2";
export const PRIMARY_COLOR = "rgba(0, 0, 0, 0.85)";
export const LIGHT_COLOR = "rgba(0, 0, 0, 0.6)";
export const LIGHT_ERROR_COLOR = "rgba(255, 0, 0, .2)";
export const LIGHT_WARNING_COLOR = "rgba(247, 197, 50, .3)";
export const LIGHT_OK_COLOR = "rgba(200, 247, 197, .5)";
export const ERROR_COLOR = "#ec0000";
export const WARNING_COLOR = "rgb(247, 197, 50)";
export const OK_COLOR = "#8ce563";
export const BLUE_COLOR = "#2660CA";
export const LIGHT_BLUE_COLOR = "#d3e2ff";
export const GREEN_COLOR = "#8ce563";
export const ORANGE_COLOR = "#ff9d37";
export const WHITE_COLOR = "#f2f2f2";
export const LOGIN = "login";
export const HOME = "inicio";
export const DARK_OK_COLOR = "#274e13";
export const DARK_WARNING_COLOR = "#7f6000";
export const DARK_ERROR_COLOR = "#660000";
export const STREAMS = "streams";
export const DETAIL_MODAL = "d";
export const METRICS_MODAL = "m";
export const ADD_STREAM_MODAL = "a";
export const TOKEN_NAME = "configuration-manager-token";
export const USER_NAME = "configuration-manager-username";
export const CANCEL_MESSAGE = "Cancelación de request.";
export const OK = "OK";
export const MUTED = "muted";

export const WIDTH_THRESHOLD_1 = 800;
export const WIDTH_THRESHOLD_2 = 1366;
export const FONT_SIZE_WIDTH_THRESHOLD = 832;
export const TABLE_WIDTH_THRESHOLD_1 = 832;
export const TABLE_WIDTH_THRESHOLD_2 = 1440;
export const MODAL_WIDTH_THRESHOLD_1 = 640;
export const MODAL_WIDTH_THRESHOLD_2 = 832;
export const MODAL_WIDTH_THRESHOLD_3 = 1080;

export const SIDEBAR_WIDTH = "100px";
export const SIDEBAR_MOBILE_WIDTH = "60px";

// export const passwordsFormData = [
//   {
//     name: "old_password",
//     verbose_name: "Contraseña actual",
//     type: "CharField",
//     required: true,
//   },
//   {
//     name: "new_password",
//     verbose_name: "Nueva contraseña",
//     type: "CharField",
//     required: true,
//   },
//   {
//     name: "repeat_new_password",
//     verbose_name: "Repetir nueva contraseña",
//     type: "CharField",
//     required: true,
//   },
// ];

export const START = "start";
export const STOP = "stop";
export const DELETE = "delete";

// // Times
// export const CHECK_CONNECTION_TIME_MS = 60000;

export const API_RESPONSE_OK = "OK";
export const API_RESPONSE_WRONG_CREDENTIALS = "WRONG_CREDENTIALS";
export const API_RESPONSE_UNKNOW_ERROR = "UNKNOW_ERROR";
