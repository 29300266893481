import React from "react";

import { Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import classNames from "classnames";
import { SelectorContainer } from "./styles";

const Selector = ({ active, logout, Icon, text, onClick, small }) => {
  const theme = useTheme();

  return (
    <SelectorContainer
      className={classNames({ active, logout })}
      onClick={onClick}
      fontSize={small ? "10px" : "12px"}
    >
      <Icon sx={active ? { color: theme.palette.blue.main } : {}} />
      <Typography
        sx={
          active
            ? {
                color: theme.palette.blue.main,
                width: "100%",
                textAlign: "center",
                fontSize: "10px",
              }
            : { width: "100%", textAlign: "center", fontSize: "10px" }
        }
      >
        {text}
      </Typography>
    </SelectorContainer>
  );
};
export default Selector;
