import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";

import { buttonStyles } from "../MainView/styles";
import { FormContainer } from "./styles";
import {
  passwordTextField,
  userTextField,
  submitButton,
  errorMessage,
} from "./styles";

const Form = ({ onSubmit, error, resetError }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    if (error) {
      resetError();
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    if (error) {
      resetError();
    }
  };

  const handleKeySubmit = (event) => {
    if (event.key === "Enter") {
      onSubmit(username, password);
    }
  };

  const loginButtonStyles = Object.assign(
    {},
    buttonStyles,
    submitButton(error)
  );

  return (
    <FormContainer>
      <TextField
        onChange={handleUsernameChange}
        error={error}
        label="Usuario"
        sx={userTextField}
      />
      <TextField
        onChange={handlePasswordChange}
        onKeyUp={handleKeySubmit}
        error={error}
        type="password"
        label="Contraseña"
        sx={passwordTextField}
      />
      {error && (
        <Typography sx={errorMessage}>
          El usuario o la contraseña son incorrectos.
        </Typography>
      )}
      <Button
        variant="contained"
        type="submit"
        sx={loginButtonStyles}
        onClick={() => onSubmit(username, password)}
      >
        Login
      </Button>
    </FormContainer>
  );
};

export default Form;
