import { create } from "zustand";

import api from "../api/configurationManagerApi";

import {
  API_RESPONSE_OK,
  API_RESPONSE_UNKNOW_ERROR,
  API_RESPONSE_WRONG_CREDENTIALS,
  TOKEN_NAME,
  USER_NAME,
} from "../constants";

export const useStoreCM = create((set, get) => {
  // Para devolver el diccionario tambien se puede: (set, get) => ({ key: value })
  return {

    // Comienza en false para que se vaya al login y muestre la ruedita.
    isLoggedIn: false,

    // Comienza en true para mostrar la ruedita mientras se valida el token.
    showLoadingPage: true,

    // Comienzan en null para no guardar credenciales invalidas.
    username: null,
    token: null,

    validateToken: async (token, username) => {
      const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
      console.log("useStore.validateToken - INICIO");
      await sleep(2000);
      api.setCredentials(token);
      await api
        .getTenants()
        .then((response) => {
          console.log("useStore.validateToken - response.ok: ", response.ok);
          console.log(
            "useStore.validateToken - location.pathname: ",
            location.pathname
          );
          if (response.ok) {
            return response.json();
          }
          return Promise.reject(response);
        })
        .then((responseJson) => {
          console.log("useStore.validateToken - response.json: ", responseJson);
          set(() => ({
            isLoggedIn: true,
            username: username,
            token: token,
            showLoadingPage: false,
          }));
        })
        .catch((error) => {
          console.log("useStore.validateToken - error: ", error);
          set(() => ({
            isLoggedIn: false,
            username: null,
            token: null,
            showLoadingPage: false,
          }));
        });
      console.log("useStore.validateToken - FIN");
    },

    tokenIsMissing: async () => {
      const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
      console.log("useStore.tokenIsMissing - seteo showLoadingPage en false");
      await sleep(2000);
      set(() => ({
        isLoggedIn: false,
        username: null,
        token: null,
        showLoadingPage: false,
      }));
    },

    login: async (username, password) => {
      console.log("useStore.login - credenciales: ", username, password);
      return await api
        .userLogin(username, password)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return Promise.reject(response);
        })
        .then((responseJson) => {
          console.log("useStore.login - login response:", responseJson);
          const token = responseJson.token;
          const username = responseJson.user.username;
          localStorage.setItem(TOKEN_NAME, token);
          localStorage.setItem(USER_NAME, username);
          set(() => ({
            isLoggedIn: true,
            username: username,
            token: token,
            showLoadingPage: false,
          }));
          return API_RESPONSE_OK;
        })
        .catch((error) => {
          console.log("useStore.login - login error:", error);
          console.log(
            "useStore.login - login error status:",
            error.status,
            error.statusText
          );
          if (error.status === 400) {
            return API_RESPONSE_WRONG_CREDENTIALS;
          } else {
            return API_RESPONSE_UNKNOW_ERROR;
          }
        });
    },

    logout: async () => {
      const token = get().token;

      console.log("useStore.logout - INICIO");
      localStorage.removeItem(TOKEN_NAME);
      localStorage.removeItem(USER_NAME);
      set(() => ({
        isLoggedIn: false,
        username: null,
        token: null,
        showLoadingPage: false,
      }));

      // Le informo al backend que ese token no se usa mas.
      return await api
        .userLogout(token)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return Promise.reject(response);
        })
        .then((responseJson) => { // Devuelve 204 no content
          console.log("useStore.logout - response:", responseJson);
          return API_RESPONSE_OK;
        })
        .catch((error) => {
          console.log("useStore.logout - logout error:", error);
          console.log(
            "useStore.logout - logout error status:",
            error.status,
            error.statusText
          );
          if (error.status === 400) {
            return API_RESPONSE_WRONG_CREDENTIALS;
          } else {
            return API_RESPONSE_UNKNOW_ERROR;
          }
        });
    },

    logState: () => {
      console.log(
        `Store - isLoggedIn:${get().isLoggedIn} - username:${
          get().username
        } - token:${get().token} - showLoadingPage:${get().showLoadingPage}`
      );
    },
  };
});

// const authenticate = useCallback(
//   (token) => {
//     console.log("authenticate en App - llamo a setCredentials con: ", token);
//     api.setCredentials(token);
//     api
//       .getTenants()
//       .then((response) => {
//         console.log("authenticate en App - response.ok: ", response.ok);
//         console.log("authenticate en App - location.pathname: ", location.pathname);
//         if (response.ok) {
//           return response.json();
//         }
//         return Promise.reject(response);
//       })
//       .then((responseJson) => {
//         console.log("authenticate en App - response.json: ", responseJson);
//         setIsAuthenticated(true);
//         navigate(`/${HOME}`);
//         // if (location.pathname === `/${LOGIN}`) {
//         //   // Si el token que tengo funciona voy a Home.
//         //   console.log("authenticate en App - navego a HOME: ", `/${HOME}`);
//         //   navigate(`/${HOME}`);
//         // }
//       })
//       .catch((error) => {
//         // Si el token no funciona voy a Login.
//         console.log("authenticate en App - error: ", error);
//         console.log(
//           "authenticate en App - error status and text: ",
//           error.status,
//           error.statusText
//         );
//         navigate(`/${LOGIN}`);
//       });
//     console.log("authenticate en App - FIN");
//   },
//   [] // navigate, location.pathname
// );