import React from "react";

import { AppBar, Container, Divider, Toolbar, Typography } from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useTheme } from "@mui/system";

import Selector from "./Selector";

const TopBar = ({ onLogout, username }) => {
  const theme = useTheme();

  return (
    <AppBar position="static">
      <Toolbar>
        <Container>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              color: "white",
              lineHeight: "64px",
              ml: "0px",
              fontFamily: "Rubik Mono One",
              fontSize: "18px",
              userSelect: "none",
              textDecoration: 'none',
            }}
          >
            CONFIGURATION MANAGER
          </Typography>
        </Container>
        <Typography align="right" sx={{ flexGrow: 1, mr: "24px" }}>
          {username}
        </Typography>
        <Divider
          orientation="vertical"
          height="40px"
          variant="middle"
          flexItem
          sx={{ background: theme.palette.gray.main, mr: "10px" }}
        />
        <Selector
          logout
          Icon={Logout}
          text="Salir"
          onClick={onLogout}
          small={false}
        />
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
