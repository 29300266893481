const API_URL = process.env.REACT_APP_CM_URL;

class configurationManagerApi {
  accessToken = null;
  base_url = API_URL.at(-1) === "/" ?  API_URL : API_URL + "/";

  setCredentials(accessToken) {
    this.accessToken = accessToken;
  }

  async userLogin(username, password) {
    // console.log("no utilizo credenciales", username, password);
    const credentials = {
      username: `${username}`,
      password: `${password}`
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(credentials),
      credentials: "omit",
    };
    return fetch(`${this.base_url}api/login/`, requestOptions);
  }

  async userLogout(token) {
    const headers = {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json"
    }
    const requestOptions = {
      method: "POST",
      headers: headers,
    };
    return fetch(`${this.base_url}api/logout/`, requestOptions); // 204 cuando sale bien
  }

  async getTenants() {
    const headers = {
      Authorization: `Token ${this.accessToken}`,
      "Content-Type": "application/json"
    }
    const requestOptions = {
      method: "GET",
      headers: headers
    };
    return fetch(`${this.base_url}api/tenants/`, requestOptions);
  }
}

const api = new configurationManagerApi();
export default api;
