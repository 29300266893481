import styled from "styled-components";

export const LogoContainer = styled.div`
  width: 100px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SelectorContainer = styled.div`
  width: 64px;
  height: 64px;

  &.active {
    background-color: rgba(38, 96, 202, 0.2);
  }

  &.logout {
    margin-top: auto;
    margin-bottom: auto;
  }

  cursor: pointer;
  border-radius: 4px;
  margin-top: 24px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-size: ${(props) => props.fontSize};
  }
`;