import styled from "styled-components";
import {
  // LIGHT_COLOR,
  // PRIMARY_COLOR,
  BACKGROUND_COLOR,
  // SIDEBAR_MOBILE_WIDTH,
  // SIDEBAR_WIDTH,
} from "../../constants";

export const Container = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  width: 100vw;
  background-color: ${BACKGROUND_COLOR};
  display: flex;
`;

export const ViewContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  width: ${(props) =>
    props.small ? "calc(100% - 60px)" : "calc(100% - 100px)"};
  height: 100%;
  margin-left: auto;
`;

// export const HeaderContainer = styled.div`
//   position: fixed;
//   height: 64px;
//   width: calc(
//     100% - ${(props) => (props.small ? SIDEBAR_MOBILE_WIDTH : SIDEBAR_WIDTH)}
//   );
//   background-color: ${PRIMARY_COLOR};
//   z-index: 1;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
// `;

// export const TitleHeader = styled.div`
//   height: 40px;
//   width: 98%;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: 72px;
// `;

// export const TitleContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   column-gap: 8px;
//   color: ${LIGHT_COLOR};
//   margin-left: auto;
//   margin-right: auto;
//   height: 40px;
//   align-items: center;
// `;

export const buttonStyles = {
  textTransform: "none",
  pl: "30px",
  pr: "30px",
  pt: "2px",
  pb: "2px",
};

// export const ModalContent = styled.div`
//   position: fixed;
//   width: ${(props) => (props.fullScreen ? "100%" : "calc(100% - 100px)")};
//   max-height: ${(props) => (props.fullScreen ? "100%" : "92%")};
//   right: 0;
//   top: ${(props) => (props.fullScreen ? 0 : props.tall ? "5%" : "20%")};
//   display: flex;
//   justify-content: center;
//   outline: none;
// `;

// export const scrollBarStyles = {
//   "&::-webkit-scrollbar": {
//     width: "5px",
//     height: "5px",
//   },

//   "&::-webkit-scrollbar-button": {
//     width: 0,
//     height: 0,
//   },

//   "&::-webkit-scrollbar-thumb": {
//     background: "gray",
//     border: "0 none gray",
//     borderRadius: "50px",
//   },

//   "&::-webkit-scrollbar-thumb:hover": {
//     background: "gray",
//   },

//   "&::-webkit-scrollbar-track": {
//     background: "#c9c8c8",
//     border: "0 none gray",
//     borderRadius: "9px",
//   },

//   "&::-webkit-scrollbar-track:hover": {
//     background: "#c9c8c8",
//   },

//   "&::-webkit-scrollbar-corner": {
//     background: "transparent",
//   },
// };

// export const dialogButtonStyles = {
//   textTransform: "none",
//   fontSize: 16,
// };
